import './playerPreview.styles.scss';

import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import momentDurationFormatSetup from 'moment-duration-format';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DeleteButton from '../../assets/images/delete-button.svg';
import PauseIcon from '../../assets/images/icons/pause.svg';
import PlayIcon from '../../assets/images/icons/play.svg';

const PlayerPreview = ({ src, onDeleteClick }) => {
    const [playing, setPlaying] = useState(false);

    const [duration, setDuration] = useState();
    const [curTime, setCurTime] = useState();
    const curPercentage = (curTime / duration) * 100;

    // eslint-disable-next-line no-unused-vars
    const formatDuration = (duration) => {
        return moment.duration(duration, 'seconds').format('mm:ss', { trim: false });
    };

    useEffect(() => {
        const audio = document.getElementById('audio-preview');

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurTime(audio.currentTime);
        };

        const setAudioTime = () => setCurTime(audio.currentTime);

        // DOM listeners: update React state on DOM events
        audio.addEventListener('loadeddata', setAudioData);

        audio.addEventListener('timeupdate', setAudioTime);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const playOrPause = () => {
        const audio = document.getElementById('audio-preview');
        // React state listeners: update DOM on React state changes
        !playing ? audio.play() : audio.pause();
        setPlaying(!playing);
    };

    return (
        <div className="form-group-file row ml-0">
            <div className="col-2 my-auto">
                {' '}
                <button
                    onClick={() => {
                        playOrPause();
                    }}
                    type="button"
                    className="btn btn-circle btn-lg">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <img src={playing ? PauseIcon : PlayIcon} alt="" />
                </button>
            </div>
            <div className="col my-auto pt-20">
                {' '}
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio id="audio-preview">
                    <source src={src} type="audio/mp3" />
                    Your browser does not support the <code>audio</code> element.
                </audio>
                <div className="bar">
                    <div
                        className="bar__progress"
                        style={{
                            background: `linear-gradient(to right, #213D7A ${curPercentage}%, #E1E1E1 0)`
                        }}>
                        <span
                            className="bar__progress__knob"
                            style={{ left: `${curPercentage - 2}%` }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col"> {formatDuration(curTime)}</div>

                    <div className="col text-right">{formatDuration(duration)}</div>
                </div>
            </div>
            <div className="col-2 my-auto">
                <button onClick={onDeleteClick} className="btn-trans" type="button">
                    <img src={DeleteButton} alt="" />
                </button>
            </div>
        </div>
    );
};

PlayerPreview.propTypes = {
    src: PropTypes.string.isRequired,
    onDeleteClick: PropTypes.func.isRequired
};

export default PlayerPreview;
