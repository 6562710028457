import '../../assets/scss/App.scss';
import './post.styles.scss';
import 'react-h5-audio-player/lib/styles.css';

import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Helmet from 'react-helmet';

import AppBar from '../../components/appBar/appBar.component';
import Map from '../../components/map/map.component';
import LocationRequestModal from '../../components/modalRequestLocation/locationRequestModal.component';
import PostForm from '../../components/postForm/postForm.component';

const Post = () => {
    // eslint-disable-next-line no-unused-vars
    const [userMark, setUserMark] = useState(undefined);
    const [modal, setModal] = useState({
        show: false,
        value: {}
    });

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setUserMark({
                    geolocation: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                });
            },
            function (error) {
                if (error.code === error.PERMISSION_DENIED) {
                    setModal({
                        show: true,
                        value: {}
                    });
                }
            }
        );
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Bicentenario de la Independecia de Costa Rica"'}</title>
                <meta
                    name="description"
                    content={'Bicentenario de la Independecia de Costa Rica"'}
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="post-page">
                <AppBar onBack={() => (window.location.href = '/')} text="Publicar" />
                {userMark && <Map userMark={userMark} />}
                <div style={{ height: '40px' }} />
                <Tabs defaultActiveKey="audio" id="tabs">
                    <Tab eventKey="audio" title="Audio">
                        <PostForm userMark={userMark} mediatype="audio" />
                    </Tab>
                    <Tab eventKey="photo" title="Fotografias">
                        <PostForm userMark={userMark} mediatype="photo" />
                    </Tab>
                    <Tab eventKey="text" title="Texto" tabClassName="last-tab">
                        <PostForm userMark={userMark} mediatype="text" />
                    </Tab>
                </Tabs>
                <LocationRequestModal
                    modal={modal}
                    onClick={() => {
                        setModal({
                            show: false,
                            value: {}
                        });
                    }}
                />
            </div>
        </>
    );
};
export default Post;
