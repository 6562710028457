import { gql } from 'apollo-boost';

export const GET_SOCIAL = gql`
    query getSocial {
        social: nodeQuery(
            filter: {
                conditions: [
                    { field: "type", value: ["links_redes_sociales"] }
                    { field: "status", value: "1", operator: EQUAL }
                ]
            }
            limit: 1
        ) {
            settings: entities {
                ... on NodeLinksRedesSociales {
                    facebook: fieldFacebook {
                        url {
                            path
                        }
                    }
                    twitter: fieldTwitter {
                        url {
                            path
                        }
                    }
                    instagram: fieldInstagram {
                        url {
                            path
                        }
                    }
                    tiktok: fieldTiktok {
                        url {
                            path
                        }
                    }
                    logoNavbar: fieldLogoNavbar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    url
                                    alt
                                    derivative(style: THUMBNAIL) {
                                        width
                                        height
                                        url
                                    }
                                }
                            }
                        }
                    }

                    logoFooter: fieldLogoNavbar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    url
                                    alt
                                }
                            }
                        }
                    }
                    logoHeroBanner: fieldLogoHeroBanner {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    url
                                    alt
                                }
                            }
                        }
                    }

                    logo: fieldLogoNavbar {
                        entity {
                            ... on MediaImage {
                                image: fieldMediaImage {
                                    url
                                    alt
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
