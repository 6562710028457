// Global Styles
import './assets/scss/theme/_global.scss';

import { ApolloProvider } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import InternalLayout from './layouts/internal/internal.component';
import AudioTour from './pages/audioTour/audioTour';
import AudioTourDetail from './pages/audioTourDetail/audioTourDetail';
import Explore from './pages/explore/explore';
import Home from './pages/home/home';
import Post from './pages/post/post';
import published from './pages/published/published';
import { client } from './Utils/apolloClient';

const RouteWrapper = ({ Component, Layout, title, description, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                Layout ? (
                    <Layout title={title} description={description} {...props}>
                        <Component {...props} />
                    </Layout>
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

RouteWrapper.propTypes = {
    Component: PropTypes.any.isRequired,
    Layout: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string
};

function App() {
    return (
        <ApolloProvider client={client}>
            <main>
                <Switch>
                    <RouteWrapper
                        path="/"
                        Layout={InternalLayout}
                        Component={Home}
                        title="Bicentenario de la Independecia de Costa Rica"
                        description="Bicentenario de la Independecia de Costa Rica"
                        exact
                    />
                    <RouteWrapper
                        path="/explore"
                        Layout={InternalLayout}
                        Component={Explore}
                        title="Bicentenario de la Independecia de Costa Rica"
                        description="Bicentenario de la Independecia de Costa Rica"
                        exact
                    />

                    <RouteWrapper
                        path="/explore/:id"
                        Layout={InternalLayout}
                        Component={Explore}
                        title="Bicentenario de la Independecia de Costa Rica"
                        description="Bicentenario de la Independecia de Costa Rica"
                        exact
                    />

                    <RouteWrapper
                        path="/audio-tour/"
                        Layout={InternalLayout}
                        Component={AudioTour}
                        title="Bicentenario de la Independecia de Costa Rica"
                        description="Bicentenario de la Independecia de Costa Rica"
                        exact
                    />

                    <RouteWrapper
                        path="/audio-tour-detail/"
                        Layout={InternalLayout}
                        Component={AudioTourDetail}
                        title="Bicentenario de la Independecia de Costa Rica"
                        description="Bicentenario de la Independecia de Costa Rica"
                        exact
                    />
                    <RouteWrapper
                        path="/post"
                        Layout={InternalLayout}
                        Component={Post}
                        title="Bicentenario de la Independecia de Costa Rica"
                        description="Bicentenario de la Independecia de Costa Rica"
                        exact
                    />

                    <RouteWrapper
                        path="/published/:id"
                        Layout={InternalLayout}
                        Component={published}
                        title="Bicentenario de la Independecia de Costa Rica"
                        description="Bicentenario de la Independecia de Costa Rica"
                        exact
                    />
                </Switch>
            </main>
        </ApolloProvider>
    );
}

export default App;
