import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import AudioPlayer from 'react-h5-audio-player';
import { Link } from 'react-router-dom';

import IconAudio from '../../assets/images/carousel-icon-audio.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import Share from '../share/share.component';

function AudioTourModal({ modal, onClick, countAudioExtra, assets, userMark }) {
    const baseurl = window.location.origin;
    return (
        <Modal
            contentClassName="container-modal-audio"
            show={modal.show}
            onHide={onClick}
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Body>
                <div className="body">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <img
                        className="close-icon"
                        onClick={onClick}
                        src={CloseIcon}
                        alt="close icon"
                    />

                    <p className="title-modal">{modal.value.title}</p>

                    {modal.value.is_anonymous === false && (
                        <p className="author-modal">
                            Por <b>{modal.value.author}</b>
                        </p>
                    )}

                    <Share socialLink={`${baseurl}/explore/${modal.value.id}`} />

                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}

                    {modal.value.media_type && modal.value.media_type === 'photo' && (
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <img className="image-modal" src={modal.value.file} alt="image modal" />
                    )}

                    {modal.value.media_type && modal.value.media_type === 'audio' && (
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <AudioPlayer
                            className="audio-player-modal"
                            autoPlay={false}
                            loop={false}
                            showDownloadProgress={false}
                            src={modal.value.file}
                            onPlay={(e) => console.log('onPlay' + e)}
                            // other props here
                        />
                    )}

                    <p className="author-description">{modal.value.description}</p>
                </div>

                {countAudioExtra !== 0 && (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <div className="container">
                        <div className="row">
                            <div className="col-10">
                                <div className="row modal-more">
                                    <div className="col-2">
                                        <img
                                            className="icon-audio-modal"
                                            src={IconAudio}
                                            alt="Card-Icon"
                                        />
                                    </div>
                                    <div className="col">
                                        <p className="text-audio-modal">
                                            Hay {countAudioExtra} audios cerca de ti
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 button-go-audio-modal">
                                <Link
                                    to={{
                                        pathname: `audio-tour-detail/`,
                                        state: { assets: assets, userMark: userMark }
                                    }}>
                                    <p>IR</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}

AudioTourModal.propTypes = {
    modal: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    countAudioExtra: PropTypes.number,
    userMark: PropTypes.object,
    assets: PropTypes.any
};
export default AudioTourModal;
