import './customMarker.styles.scss';

import { Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line no-unused-vars
const CustomMarker = ({ position, icon, onClick }) => {
    return <Marker position={position} icon={icon} onClick={onClick} />;
};

CustomMarker.propTypes = {
    position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    }),
    title: PropTypes.string,

    icon: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.number
};

export default CustomMarker;
