import './share.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';

// eslint-disable-next-line no-unused-vars
import WhatApps from '../../assets/images/icons/bi_whatsapp.svg';
import FacebookIcon from '../../assets/images/icons/facebook.svg';
import InIcon from '../../assets/images/icons/in.svg';
import TwitterIcon from '../../assets/images/icons/twitt.svg';

const Share = ({ socialLink, title }) => {
    return (
        <div className="custom-social-share">
            <ul>
                <li>
                    <FacebookShareButton
                        url={socialLink}
                        quote={title}
                        className="default-social-button">
                        <img src={FacebookIcon} alt="Facebook" />
                    </FacebookShareButton>
                </li>

                <li>
                    <LinkedinShareButton
                        url={socialLink}
                        quote={title}
                        className="default-social-button">
                        <img src={InIcon} alt="Facebook" />
                    </LinkedinShareButton>
                </li>
                <li>
                    <TwitterShareButton
                        url={socialLink}
                        title={title}
                        className="default-social-button">
                        <img src={TwitterIcon} alt="Twitter" />
                    </TwitterShareButton>
                </li>
                <li>
                    <WhatsappShareButton
                        url={socialLink}
                        title={title}
                        className="default-social-button email">
                        <img src={WhatApps} alt="Email" />
                    </WhatsappShareButton>
                </li>
            </ul>
        </div>
    );
};

Share.propTypes = {
    socialLink: PropTypes.string,
    title: PropTypes.string
};

export default Share;
