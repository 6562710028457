import './menu.styles.scss';

import * as PropTypes from 'prop-types';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

import LazyImage from '../lazyImage/lazyImage.component';

function Menu({ srcBanner, srcHeroBanner }) {
    function openLink(path) {
        window.location.href = process.env.REACT_APP_WEBSITE + path;
    }
    return (
        <>
            <div className="main-menu-wrapp">
                <Navbar className="main-menu" collapseOnSelect expand={false}>
                    <Navbar.Brand>
                        <LazyImage
                            className="branding-color"
                            src={srcHeroBanner}
                            onClick={() => {
                                openLink('');
                            }}
                            alt="main logo"
                        />
                        <LazyImage
                            onClick={() => {
                                openLink('');
                            }}
                            width="133"
                            height="84"
                            className="branding-flat"
                            src={srcBanner}
                            alt="main logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <div className="main-menu__items">
                        {defaultMenu.map((item, index) => (
                            <Navbar.Collapse key={index} id="responsive-navbar-nav">
                                <Nav>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}

                                    {item.internalLink ? (
                                        <NavLink
                                            to={item.link}
                                            activeClassName="main-menu__item-active">
                                            {item.text}
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                            activeClassName="main-menu__item-active"
                                            to={{}}
                                            onClick={() => {
                                                openLink(item.link);
                                            }}>
                                            {item.text}
                                        </NavLink>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        ))}
                    </div>
                </Navbar>
            </div>
        </>
    );
}

const defaultMenu = [
    {
        text: 'Programación',
        link: '/agenda/programacion'
    },
    {
        text: 'Nuestros 200 años',
        link: '/nuestra-historia'
    },
    {
        text: 'Participá',
        link: '/participa'
    },
    {
        text: 'Escribamos historia',
        link: '/',
        internalLink: true
    },
    {
        text: 'Prensa',
        link: '/prensa'
    },
    {
        text: 'Acerca de',
        link: '/acerca-de'
    }
];

Menu.propTypes = {
    srcBanner: PropTypes.string,
    srcHeroBanner: PropTypes.string
};

export default Menu;
