import './hero.styles.scss';

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import AudioTourHomeImage from '../../assets/images/btn-audio-tour.svg';
import BtnExploreImage from '../../assets/images/btn-explore.svg';
import BtnPostImage from '../../assets/images/btn-post.svg';
import InfoButton from '../../assets/images/icons/info.svg';
import MapHomeImage from '../../assets/images/map-home.svg';
import OnboardingCarousel from '../onboardingCarousel/onboardingCarousel';
// eslint-disable-next-line no-unused-vars
function Hero() {
    const [showModalCarousel, setShowModalCarousel] = useState(false);
    const [cookies, setCookie] = useCookies(['modalCarousel']);

    useEffect(() => {
        const showCarousel = cookies.modalCarousel;

        if (showCarousel === undefined) {
            setShowModalCarousel(true);
        }
    }, []);
    return (
        <>
            <div className="hero-slider">
                <div className="hero-slider__slide">
                    <img className="hero-slider__img" src={MapHomeImage} alt="Map" />
                </div>

                <div
                    className={`container home-component shards-counter shards-counter--1  ${
                        showModalCarousel === undefined || showModalCarousel === false
                            ? 'home-component-margin-bottom'
                            : 'home-component-margin-top'
                    }`}>
                    <div className="container">
                        <div className="row">
                            <div className="counter-item col-12 mb-sm-0 text-center headline-container">
                                <h2 className="text-primary-color home__title">
                                    Conoce la historia de Costa Rica y su gente
                                </h2>
                            </div>
                        </div>
                        <OnboardingCarousel
                            show={showModalCarousel}
                            onClose={() => {
                                setShowModalCarousel(false);
                                setCookie('modalCarousel', false, { path: '/', maxAge: 31536000 });
                            }}
                        />
                        <div className="row justify-content-center">
                            <div className="counter-item col-12 mb-sm-0 text-center ">
                                <div className="row">
                                    <Link to="/explore" className="col-4 text-center">
                                        <div className="item-option mx-auto">
                                            <img src={BtnExploreImage} alt="explore" />
                                        </div>
                                        <p className="title-option">Explorar</p>
                                    </Link>

                                    <Link to="/audio-tour" className="col-4 text-center">
                                        <div>
                                            <div className="item-option mx-auto">
                                                <img src={AudioTourHomeImage} alt="explore" />
                                            </div>
                                            <p className="title-option">Audio tour</p>
                                        </div>
                                    </Link>
                                    <Link to="/post" className="col-4 text-center">
                                        <div className="item-option mx-auto">
                                            <img src={BtnPostImage} alt="explore" />
                                        </div>
                                        <p className="title-option">Publica tu historia</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-button">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                        <img
                            src={InfoButton}
                            alt="info"
                            onClick={() => {
                                setShowModalCarousel(!showModalCarousel);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
