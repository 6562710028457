import '../../assets/scss/App.scss';
import '../explore/explore.styles.scss';
import 'react-multi-carousel/lib/styles.css';

import { GoogleMap, LoadScript } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import Carousel from 'react-multi-carousel';
import { useParams } from 'react-router-dom';

import IconAudio from '../../assets/images/carousel-icon-audio.svg';
import IconImage from '../../assets/images/carousel-icon-image.svg';
import IconText from '../../assets/images/carousel-icon-text.svg';
import AppBar from '../../components/appBar/appBar.component';
import CarouselCard from '../../components/carouselCard/carouselCard.component';
import HistoryModal from '../../components/history-modal/historyModal.component';
import CustomMarker from '../../components/map/childs/customMarker.component';
import { GetRequest } from '../../Utils/request';
import { getDistance } from '../../Utils/utils';

const Explore = () => {
    const [userMark, setUserMark] = useState(undefined);

    const { id } = useParams();

    const [assets, setAssets] = useState([]);

    const maxDistance = 1000;

    const mapRef = useRef(null);

    const [modal, setModal] = useState({
        show: false,
        value: {}
    });

    const containerStyle = {
        width: '100%',
        height: '361px'
    };

    const mapStyles = [];

    useEffect(() => {
        console.log('Inicio');

        if (id !== undefined) {
            GetRequest(
                `${process.env.REACT_APP_BASE_URL_API}/api/2/assets/${id}/?format=json&submitted=true`
            ).then((value) => {
                setModal({
                    show: true,
                    value: value.data
                });
            });
        }
        GetRequest(
            `${process.env.REACT_APP_BASE_URL_API}/api/2/projects/1/assets/?format=json&submitted=true`
        ).then((values) => {
            setAssets(values.data);
        });

        navigator.geolocation.getCurrentPosition(
            function (position) {
                setUserMark({
                    geolocation: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                });
            },
            function (error) {
                if (error.code === error.PERMISSION_DENIED) {
                    setUserMark({
                        geolocation: {
                            lat: 9.932562,
                            lng: -84.079658
                        }
                    });
                }
            }
        );
    }, []);

    const itemCard = function (item, index) {
        // eslint-disable-next-line no-unused-vars
        const distance = getDistance(userMark.geolocation, {
            lng: item.longitude,
            lat: item.latitude
        });

        let icon = null;

        if (item.media_type === 'audio') {
            icon = IconAudio;
        } else if (item.media_type === 'photo') {
            icon = IconImage;
        } else {
            icon = IconText;
        }

        if (distance <= maxDistance) {
            return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <CarouselCard
                    key={`card--${index}`}
                    id="1"
                    icon={icon}
                    title={item.title}
                    isAnonymous={item.is_anonymous}
                    onClick={() =>
                        setModal({
                            show: true,
                            value: item
                        })
                    }
                    description={item.author}
                />
            );
        }
    };

    function handleLoad(map) {
        mapRef.current = map;
    }

    // eslint-disable-next-line no-unused-vars
    function handleCenterChanged() {
        console.log('handleCenterChanged');
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        const newLocation = {
            geolocation: newPos
        };
        console.log(newPos);

        const distance = getDistance(userMark.geolocation, newLocation.geolocation);

        const readyForUpdate = distance >= 50;

        console.log(readyForUpdate);

        if (readyForUpdate && userMark !== newLocation) {
            setUserMark(newLocation);
        }
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2.5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2.5
        }
    };

    const carouselSetting = {
        infinite: false,
        arrows: false
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Bicentenario de la Independecia de Costa Rica"'}</title>
                <meta
                    name="description"
                    content={'Bicentenario de la Independecia de Costa Rica"'}
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="explore-page">
                <AppBar onBack={() => (window.location.href = '/')} text="Explorar" />
                {assets && userMark && (
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_GOOGLE_MAPS_API}>
                        <GoogleMap
                            mapContainerClassName="map-assets"
                            onLoad={handleLoad}
                            options={{
                                styles: mapStyles
                            }}
                            mapContainerStyle={containerStyle}
                            onDragEnd={handleCenterChanged}
                            center={userMark.geolocation}
                            zoom={16}>
                            {assets &&
                                assets.map((item, index) => (
                                    <CustomMarker
                                        onClick={() =>
                                            setModal({
                                                show: true,
                                                value: item
                                            })
                                        }
                                        key={`event-map-${item.startDate}-${index}`}
                                        position={{
                                            lng: item.longitude,
                                            lat: item.latitude
                                        }}
                                        icon={'/marker.png'}
                                    />
                                ))}

                            {userMark && assets === undefined && (
                                <CustomMarker
                                    position={userMark.geolocation}
                                    icon={'/marker.png'}
                                />
                            )}
                        </GoogleMap>
                    </LoadScript>
                )}

                <h2 className="title-history">Historias cerca de tu búsqueda:</h2>

                {userMark && (
                    <div className="shards-blog shards-blog--1">
                        <Carousel responsive={responsive} {...carouselSetting}>
                            {assets && assets.map((item, index) => itemCard(item, index))}
                        </Carousel>
                    </div>
                )}
                {assets && userMark && (
                    <HistoryModal
                        onClick={() =>
                            setModal({
                                show: false,
                                value: {}
                            })
                        }
                        modal={modal}
                    />
                )}
            </div>
        </>
    );
};

export default Explore;
