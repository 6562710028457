import '../../assets/scss/App.scss';
import './audioTour.styles.scss';
import 'react-h5-audio-player/lib/styles.css';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import AppBar from '../../components/appBar/appBar.component';
import AudioTourModal from '../../components/audioTourModal/audioTourModal.component';
// eslint-disable-next-line no-unused-vars
import CustomMarker from '../../components/map/childs/customMarker.component';
import LocationRequestModal from '../../components/modalRequestLocation/locationRequestModal.component';
import { GetRequest } from '../../Utils/request';
import { getDistance } from '../../Utils/utils';

// eslint-disable-next-line no-unused-vars
const AudioTour = () => {
    const containerStyle = {
        width: '100%',
        height: '500px'
    };

    const mapStyles = [];
    const [userMark, setUserMark] = useState(undefined);
    const [userMarkOld, setUserMarkOld] = useState(undefined);

    const { id } = useParams();

    const [assets, setAssets] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [audioExtraCount, setAudioExtraCount] = useState(0);

    const [modalLocation, setModalLocation] = useState({
        show: false,
        value: {}
    });

    // eslint-disable-next-line no-unused-vars
    const [modal, setModal] = useState({
        show: false,
        value: {}
    });

    useEffect(() => {
        console.log('Inicio');

        if (id !== undefined) {
            GetRequest(
                `${process.env.REACT_APP_BASE_URL_API}/api/2/assets/${id}/?format=json&submitted=true`
            ).then((value) => {
                setModal({
                    show: true,
                    value: value
                });
            });
        }

        GetRequest(
            `${process.env.REACT_APP_BASE_URL_API}/api/2/projects/1/assets/?format=json&mediatype=audio&submitted=true`
        ).then((values) => {
            setAssets(values.data);
            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(
                    function (position) {
                        const currentLocation = {
                            geolocation: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            }
                        };

                        console.log(position.coords.latitude);
                        console.log(position.coords.longitude);

                        if (userMarkOld !== currentLocation) {
                            setUserMark(currentLocation);
                        }

                        watchPosition(currentLocation, values.data);
                    },
                    function (error) {
                        if (error.code === error.PERMISSION_DENIED) {
                            setModalLocation({
                                show: true,
                                value: {}
                            });
                        }
                    }
                );
            }

            //watchPosition(values.data).then((r) => console.log('then watch ' + r));
        });
    }, []);

    // eslint-disable-next-line no-unused-vars
    const watchPosition = (currentGeo, listAssets) => {
        setUserMarkOld(currentGeo);
        let tempAssets = [];
        listAssets.forEach((asset) => {
            const geoAsset = {
                lat: asset.latitude,
                lng: asset.longitude
            };

            const distance = getDistance(geoAsset, {
                lat: currentGeo.geolocation.lat,
                lng: currentGeo.geolocation.lng
            });

            if (distance < 10) {
                tempAssets.push(asset);
            }

            //console.log(distance);
        });

        if (tempAssets[0] ?? false) {
            const newArray = tempAssets.filter((value) => value !== tempAssets[0]);
            console.log(newArray);

            setFilteredAssets(newArray);
            setAudioExtraCount(newArray.length);

            setModal({
                show: true,
                value: tempAssets[0]
            });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Bicentenario de la Independecia de Costa Rica"'}</title>
                <meta
                    name="description"
                    content={'Bicentenario de la Independecia de Costa Rica"'}
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="audio-tour-page">
                <div className="top-content">
                    <AppBar onBack={() => (window.location.href = '/')} text="Audio Tour" />
                    <h2 className="title-history">Historias cerca de tu ubicación (1 km)</h2>
                </div>

                {assets && userMark && (
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_GOOGLE_MAPS_API}>
                        <GoogleMap
                            mapContainerClassName="map"
                            options={{
                                styles: mapStyles,
                                streetViewControl: false,
                                fullscreenControl: false,
                                mapTypeControl: false,
                                scaleControl: true // allow scale controle
                            }}
                            mapContainerStyle={containerStyle}
                            center={userMark.geolocation}
                            zoom={20}>
                            {assets &&
                                assets.map((item, index) => (
                                    <CustomMarker
                                        title={item.title}
                                        description={item.description}
                                        key={`event-map-${item.startDate}-${index}`}
                                        position={{
                                            lng: item.longitude,
                                            lat: item.latitude
                                        }}
                                        onClick={() => {
                                            console.log('asdas');
                                            setModal({
                                                show: true,
                                                value: item
                                            });
                                        }}
                                        icon={'/marker.png'}
                                    />
                                ))}

                            <div>Texto</div>

                            {userMark && (
                                <Marker position={userMark.geolocation} icon={'/marker-tour.png'} />
                            )}
                        </GoogleMap>
                    </LoadScript>
                )}

                {assets && userMark && (
                    <>
                        <AudioTourModal
                            onClick={() =>
                                setModal({
                                    show: false,
                                    value: {}
                                })
                            }
                            countAudioExtra={audioExtraCount}
                            assets={filteredAssets}
                            userMark={userMark}
                            modal={modal}
                        />
                    </>
                )}
            </div>
            <LocationRequestModal
                modal={modalLocation}
                onClick={() => {
                    setModalLocation({
                        show: false,
                        value: {}
                    });
                }}
            />
        </>
    );
};

export default AudioTour;
