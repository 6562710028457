import * as PropTypes from 'prop-types';
import React from 'react';

import DotsIcon from '../../assets/images/icons/dots.svg';

function ModalAndroidCookie({ show }) {
    if (show) {
        return (
            <div className="androidChromeInstallPrompt">
                <div className="arrow" />
                <div className="box">
                    <div className="title">Instalar Aplicación</div>

                    <div className="home-steps">
                        <p className="first-step">
                            1. En Chrome, haga click en <img src={DotsIcon} alt="tres puntos" />
                        </p>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p className="last-step">
                            2. Toca <b>Añadir a pantalla de inicio</b>
                        </p>
                    </div>

                    <p className="orUseWeb">
                        Si lo desea también puede utilizar la aplicación en su navegador,
                        simplemente haga click afuera de estas instrucciones.
                    </p>
                </div>
                <div className="arrow" />
            </div>
        );
    }

    return <> </>;
}

ModalAndroidCookie.propTypes = {
    show: PropTypes.bool.isRequired
};

export default ModalAndroidCookie;
