import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import AudioPlayer from 'react-h5-audio-player';

import CloseIcon from '../../assets/images/close-icon.svg';
import { GetRequest } from '../../Utils/request';
import Share from '../share/share.component';

function HistoryModal({ modal, onClick }) {
    // eslint-disable-next-line no-unused-vars
    const [address, setAddress] = useState('');

    const baseurl = window.location.origin;

    // eslint-disable-next-line no-unused-vars
    function getAddress() {
        const lat = modal.value.latitude;
        const lng = modal.value.longitude;

        if (!(lat === undefined)) {
            GetRequest(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP_GOOGLE_MAPS_API}`
            ).then((value) => {
                setAddress(value?.data?.results[0].formatted_address);
            });
        }
    }
    return (
        <Modal
            contentClassName="container-modal"
            show={modal.show}
            onHide={onClick}
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Body>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img className="close-icon" onClick={onClick} src={CloseIcon} alt="close icon" />

                <p className="title-modal">{modal.value.title}</p>
                {modal.value.is_anonymous === false && (
                    <p className="author-modal">
                        Por <b>{modal.value.author}</b>
                    </p>
                )}

                <p className="location-modal">{address}</p>

                <Share socialLink={`${baseurl}/explore/${modal.value.id}`} />

                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}

                {modal.value.media_type === 'photo' && (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img className="image-modal" src={modal.value.file} alt="image modal" />
                )}

                {modal.value.media_type === 'audio' && (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <AudioPlayer
                        className="audio-player-modal"
                        autoPlay={false}
                        loop={false}
                        showDownloadProgress={false}
                        src={modal.value.file}
                        onPlay={(e) => console.log('onPlay' + e)}
                        // other props here
                    />
                )}

                <p className="author-description">{modal.value.description}</p>
            </Modal.Body>
        </Modal>
    );
}

HistoryModal.propTypes = {
    modal: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};
export default HistoryModal;
