import './postForm.styles.scss';

import Compressor from 'compressorjs';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import UploadIcon from '../../assets/images/icons/upload.svg';
// eslint-disable-next-line no-unused-vars
import PostRequest from '../../Utils/request';
import LocationRequestModal from '../modalRequestLocation/locationRequestModal.component';
import PlayerPreview from '../playerPreview/playerPreview.component';

const PostForm = ({ mediatype, userMark }) => {
    const fileRef = React.createRef();
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState();
    const [state, setState] = useState({
        value: ''
    });

    const [modal, setModal] = useState({
        show: false,
        value: {}
    });

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        console.log(process.env.REACT_APP_KEY);
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(userMark);

        if (userMark === undefined) {
            setModal({
                show: true,
                value: {}
            });
            return;
        }

        const title = event.currentTarget.elements.title?.value;

        const description = event.currentTarget.elements.description?.value;

        const name = event.currentTarget.elements.name?.value;

        const email = event.currentTarget.elements.email?.value;

        const publishAnonymous = event.currentTarget.elements.publishAnonymous?.checked;

        let formData = new FormData();
        formData.append('file', selectedFile === undefined ? '' : selectedFile);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('author', name);
        formData.append('email_author', email);
        formData.append('is_anonymous', publishAnonymous.toString().capitalize());
        formData.append('filename', '');
        formData.append('latitude', userMark.geolocation.lat);
        formData.append('longitude', userMark.geolocation.lng);
        formData.append('language', 1);
        formData.append('project', 1);
        formData.append('envelope_ids', 1);
        formData.append('user', 1);
        formData.append('mediatype', mediatype);
        formData.append('submitted', 'False');
        formData.append('session', '1');
        formData.append('accept_terms', true);
        formData.append('no_responsibility', true);
        formData.append('publish_anonymous', publishAnonymous);

        PostRequest(`${process.env.REACT_APP_BASE_URL_API}/api/2/assets/`, formData).then(function (
            response
        ) {
            window.location.href = '/published/' + response.data.id;
        });
    };

    const textFile = () => {
        return mediatype === 'audio' ? 'Agrega tu audio ' : 'Agrega tu fotografía';
    };

    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    };
    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        if (mediatype === 'photo') {
            new Compressor(e.target.files[0], {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    setSelectedFile(compressedResult);
                }
            });
        } else {
            setSelectedFile(e.target.files[0]);
        }
    };

    function openLinkTerms() {
        console.log('click aca');

        //window.open(process.env.REACT_APP_WEBSITE + '/terminos', '_blank');
    }

    return (
        <>
            <h2 className="title-text">
                Contanos la historia de <br /> donde estés
            </h2>
            <p className="required-text">Obligatorios*</p>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="title">
                    <Form.Label>TÍTULO DE TU HISTORIA</Form.Label>
                    <Form.Control type="text" placeholder="" required />
                </Form.Group>

                {mediatype !== 'text' && (
                    <>
                        {' '}
                        <Form.Label>ARCHIVO ADJUNTO</Form.Label>
                        {!selectedFile && (
                            <>
                                <div className="form-group-file form-group-file--center">
                                    <button
                                        onClick={() => {
                                            fileRef.current.click();
                                        }}
                                        className="btn btn-circle btn-lg btn-upload"
                                        type="button">
                                        <img src={UploadIcon} alt="" />
                                    </button>

                                    <p>{textFile()}</p>
                                </div>
                                <input hidden type="file" ref={fileRef} onChange={onSelectFile} />
                            </>
                        )}
                        {selectedFile && preview && mediatype === 'photo' && (
                            <img
                                style={{ width: '100%', maxHeight: '230px', marginBottom: '43px' }}
                                src={preview}
                                alt="text"
                            />
                        )}
                        {selectedFile && preview && mediatype === 'audio' && (
                            <PlayerPreview
                                src={preview}
                                onDeleteClick={() => {
                                    setSelectedFile(undefined);
                                }}
                            />
                        )}
                    </>
                )}

                <Form.Group controlId="description">
                    <Form.Label>DESCRIPCIÓN</Form.Label>
                    <Form.Control as="textarea" rows={10} />
                </Form.Group>

                <Form.Group controlId="name">
                    <Form.Label>Nombre completo</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={state.value}
                        onChange={(e) => {
                            let value = e.target.value;

                            value = value.replace(/[^A-Za-z\s]/gi, '');

                            setState({
                                value
                            });
                        }}
                        placeholder=""
                    />
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Correo</Form.Label>
                    <Form.Control required type="email" placeholder="" />
                </Form.Group>

                <Form.Group controlId="publishAnonymous">
                    <Form.Check type="checkbox" label="Publicar de manera anónima" />
                </Form.Group>

                <p className="text-no-delete">
                    La información no se puede eliminar una vez compatida{' '}
                </p>
                <Form.Group controlId="acceptTerms">
                    <Form.Check
                        required
                        type="checkbox"
                        onClick={openLinkTerms}
                        label={acceptTerms()}
                    />
                </Form.Group>

                <Form.Group controlId="noResponsibility">
                    <Form.Check required type="checkbox" label={noResponsibility()} />
                </Form.Group>

                <Button type="submit" className="button-primary" variant="primary">
                    Compartir historia
                </Button>
            </Form>

            <LocationRequestModal
                modal={modal}
                onClick={() => {
                    setModal({
                        show: false,
                        value: {}
                    });
                }}
            />
        </>
    );

    function acceptTerms() {
        return (
            <div>
                Acepto {''}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                    target="_blank"
                    className="link-checkbox"
                    href={process.env.REACT_APP_WEBSITE + '/terminos'}>
                    <b>terminos y condiciones</b>
                </a>
            </div>
        );
    }
    function noResponsibility() {
        return (
            <div className="space">
                Acepto {''}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                    target="_blank"
                    className="link-checkbox"
                    href={process.env.REACT_APP_WEBSITE + '/terminos'}>
                    <b>liberación de responsabilidad</b>
                </a>
            </div>
        );
    }
};

PostForm.propTypes = {
    mediatype: PropTypes.string,
    userMark: PropTypes.any
};
export default PostForm;
