import './appBar.styles.scss';
import './appBar.styles.scss';

import * as PropTypes from 'prop-types';
import React from 'react';

import BackIcon from '../../assets/images/icons/back.svg';
const AppBar = ({ text, onBack }) => {
    return (
        <>
            <div className="row app-bar">
                <div className="col-4">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <img src={BackIcon} onClick={onBack} alt="back" />
                </div>
                <div className="col title">{text}</div>
            </div>
        </>
    );
};

AppBar.propTypes = {
    text: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired
};

export default AppBar;
