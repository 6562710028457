import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';

import AudioTourCarousel from '../../assets/images/audio-tour-carousel.svg';
import CloseCarousel from '../../assets/images/close-icon-carousel.svg';
import ExploreCarousel from '../../assets/images/explore-carousel.svg';
import PostCarousel from '../../assets/images/post-carousel.svg';

const items = [
    {
        src: ExploreCarousel,
        title: 'Encuentra historias',
        subtitle:
            'Dentró de Explorar podras buscar historias de nuestro pueblo en la ubicación que desees.'
    },
    {
        src: AudioTourCarousel,
        title: 'Descubre historias',
        subtitle:
            'Con el Audio tour podras ir descubriendo las historias que se encuentran cerca de ti. '
    },
    {
        src: PostCarousel,
        title: 'Cuentanos tu historia',
        subtitle:
            'Se parte de nuestra historia cuentale a las personas sobre tus anectodas y hechos historicos.'
    }
];

function OnboardingCarousel({ show, onClose }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}>
                <div className="item-body">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img className="carousel-item-image" src={item.src} alt="image" />

                    <p className="carousel-item-title">{item.title}</p>
                    <p className="carousel-item-subtitle">{item.subtitle}</p>
                </div>
            </CarouselItem>
        );
    });

    if (show) {
        return (
            <div className="firstModalPrompt">
                <div
                    className="wrapper-slider"
                    style={{
                        position: 'relative'
                    }}>
                    <div
                        className="wrapper-slider"
                        style={{
                            position: 'absolute',
                            zIndex: '9',
                            right: '12px',
                            cursor: 'pointer'
                        }}>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                        <img onClick={onClose} className="" src={CloseCarousel} alt="close" />
                    </div>
                    <div className="box">
                        <Carousel
                            activeIndex={activeIndex}
                            autoPlay={false}
                            interval={false}
                            next={next}
                            previous={previous}>
                            <CarouselIndicators
                                items={items}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                            />
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}

                            {slides}
                            <CarouselControl
                                direction="prev"
                                directionText="Previous"
                                onClickHandler={previous}
                            />
                            <CarouselControl
                                direction="next"
                                directionText="Next"
                                onClickHandler={next}
                            />
                        </Carousel>
                    </div>
                </div>
            </div>
        );
    }

    return <> </>;
}

OnboardingCarousel.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default OnboardingCarousel;
