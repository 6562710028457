import '../../assets/scss/App.scss';

import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { BrowserView, MobileView } from 'react-device-detect';
import Helmet from 'react-helmet';

// Components
import Menu from '../../components/menu/menu.component';
import { GET_SOCIAL } from '../../graphQL/queries/configQuery';

const InternalLayout = ({ children, title, description }) => {
    // eslint-disable-next-line no-unused-vars
    const { loading, error, data } = useQuery(GET_SOCIAL);

    return (
        <>
            <BrowserView>
                <DesktopLayout />
            </BrowserView>
            <MobileView>
                <MobileLayout />
            </MobileView>
        </>
    );

    function DesktopLayout() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <div className="container container-mobile">
                    <div className="row content-desktop">
                        <div className="col-md-4 col-lg-6 content-left col-xl-7">
                            <img className="logo-desktop" src="/logo-desktop.svg" alt="logo" />
                            <h1 className="title-desktop">¡200 años COSTA RICA!</h1>
                            <h3 className="subtitle-desktop">
                                ¡Te invitamos a celebrar <br /> nuestra historia!
                            </h3>
                        </div>
                        <div className="col-md-8 col-lg-6 col-sm-12 col-xl-5 p-0">
                            <div className="content-right-wrapper">
                                <MobileLayout />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function MobileLayout() {
        return (
            <>
                {!loading && !error && (
                    <>
                        <Menu
                            srcBanner={data.social.settings[0].logoHeroBanner.entity.image.url}
                            srcHeroBanner={data.social.settings[0].logoHeroBanner.entity.image.url}
                        />
                        {children}
                    </>
                )}

                <CookieConsent
                    disableStyles={true}
                    enableDeclineButton
                    buttonClasses="btn btn-primary float-right"
                    declineButtonClasses="btn btn-outline"
                    containerClasses="cookie-message"
                    buttonText="Aceptar"
                    declineButtonText="Cancelar"
                    contentClasses="text-cookie">
                    Este sitio web usa cookies, para su mejor funcionamineto. De no aceptarse puede
                    perjudicar el funcionamiento del sitio. Puede leer más sobre el uso de cookies
                    en nuestra{' '}
                    <a href={process.env.REACT_APP_WEBSITE + '/terminos'}>
                        <b>términos y condiciones</b>
                    </a>
                </CookieConsent>
            </>
        );
    }
};

InternalLayout.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.string,
    description: PropTypes.string
};

export default InternalLayout;
