import '../../assets/scss/App.scss';
import './audioTourDetail.styles.scss';
import 'react-h5-audio-player/lib/styles.css';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import Helmet from 'react-helmet';
import Carousel from 'react-multi-carousel';
import { useLocation } from 'react-router';

import IconAudio from '../../assets/images/carousel-icon-audio.svg';
import IconImage from '../../assets/images/carousel-icon-image.svg';
import IconText from '../../assets/images/carousel-icon-text.svg';
// eslint-disable-next-line no-unused-vars
import AppBar from '../../components/appBar/appBar.component';
import CarouselCard from '../../components/carouselCard/carouselCard.component';
import HistoryModal from '../../components/history-modal/historyModal.component';
// eslint-disable-next-line no-unused-vars
import CustomMarker from '../../components/map/childs/customMarker.component';
import { getDistance } from '../../Utils/utils';

// eslint-disable-next-line no-unused-vars
const AudioTourDetail = () => {
    const containerStyle = {
        width: '100%',
        height: '500px'
    };
    const [assets, setAssets] = useState([]);

    const mapStyles = [];
    const [userMark, setUserMark] = useState(undefined);

    const { state } = useLocation();

    // eslint-disable-next-line no-unused-vars
    const [modal, setModal] = useState({
        show: false,
        value: {}
    });
    useEffect(() => {
        console.log(state);
        setAssets(state.assets);
        setUserMark(state.userMark);
    }, []);

    const itemCard = function (item, index) {
        // eslint-disable-next-line no-unused-vars
        const distance = getDistance(userMark.geolocation, {
            lng: item.longitude,
            lat: item.latitude
        });

        let icon = null;

        if (item.media_type === 'audio') {
            icon = IconAudio;
        } else if (item.media_type === 'photo') {
            icon = IconImage;
        } else {
            icon = IconText;
        }

        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <CarouselCard
                key={`card--${index}`}
                id="1"
                icon={icon}
                title={item.title}
                onClick={() =>
                    setModal({
                        show: true,
                        value: item
                    })
                }
                description={item.author}
            />
        );
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2.5
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2.5
        }
    };

    const carouselSetting = {
        infinite: false,
        arrows: false
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Bicentenario de la Independecia de Costa Rica"'}</title>
                <meta
                    name="description"
                    content={'Bicentenario de la Independecia de Costa Rica"'}
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="audio-tour-page-detail">
                <div className="top-content">
                    <AppBar
                        onBack={() => (window.location.href = '/audio-tour')}
                        text="Audio Tour"
                    />
                    <h2 className="title-history">Historias cerca de tu ubicación (1 km)</h2>
                </div>

                {assets && userMark && (
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_GOOGLE_MAPS_API}>
                        <GoogleMap
                            mapContainerClassName="map"
                            options={{
                                styles: mapStyles,
                                streetViewControl: false,
                                fullscreenControl: false,
                                mapTypeControl: false,
                                scaleControl: true // allow scale controle
                            }}
                            mapContainerStyle={containerStyle}
                            center={userMark.geolocation}
                            zoom={20}>
                            {assets &&
                                assets.map((item, index) => (
                                    <CustomMarker
                                        title={item.title}
                                        description={item.description}
                                        key={`event-map-${item.startDate}-${index}`}
                                        position={{
                                            lng: item.longitude,
                                            lat: item.latitude
                                        }}
                                        onClick={() =>
                                            setModal({
                                                show: true,
                                                value: item
                                            })
                                        }
                                        icon={'/marker.png'}
                                    />
                                ))}

                            {userMark && (
                                <Marker position={userMark.geolocation} icon={'/marker-tour.png'} />
                            )}
                        </GoogleMap>
                    </LoadScript>
                )}

                <div className="shards-blog shards-blog--1 slider">
                    <Carousel responsive={responsive} {...carouselSetting}>
                        {assets && assets.map((item, index) => itemCard(item, index))}
                    </Carousel>
                </div>

                {assets && userMark && (
                    <>
                        <HistoryModal
                            onClick={() =>
                                setModal({
                                    show: false,
                                    value: {}
                                })
                            }
                            modal={modal}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default AudioTourDetail;
