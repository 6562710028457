import * as PropTypes from 'prop-types';
import React from 'react';

import PlusSafariIcon from '../../assets/images/icons/plus-safari.svg';
import ShareIcon from '../../assets/images/icons/share-icon.svg';

function ModalIphoneCookie({ show }) {
    if (show) {
        return (
            <div className="appleSafariInstallPrompt">
                <div className="box">
                    <div className="title">Instalar Aplicación</div>
                    <div className="home-steps">
                        <p>
                            1. En Safari, haga click en
                            <img src={ShareIcon} alt="share" />
                        </p>
                        <p>
                            2. Haga click en
                            <img src={PlusSafariIcon} alt="plus" />
                        </p>
                    </div>
                    <p className="orUseWeb">
                        Si lo desea también puede utilizar la aplicación en su navegador,
                        simplemente haga click afuera de estas instrucciones.
                    </p>
                </div>
                <div className="arrow" />
            </div>
        );
    }

    return <> </>;
}

ModalIphoneCookie.propTypes = {
    show: PropTypes.bool.isRequired
};

export default ModalIphoneCookie;
