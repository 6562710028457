import '../../assets/scss/App.scss';
import './home.scss';

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import * as rdd from 'react-device-detect';
import Helmet from 'react-helmet';

import Hero from '../../components/heroSlider/hero.component';
import ModalAndroidCookie from '../../components/modalAndroidCookie/modalAndroidCookie.component';
import ModalIphoneCookie from '../../components/modalIphoneCookie/modalIphoneCookie.component';

function Home() {
    const [modalAndroid, setModalAndroid] = useState(false);

    const [modalIphone, setModalIphone] = useState(false);

    const [cookies, setCookie] = useCookies(['modalPWA']);

    useEffect(() => {
        const showModalPWA = cookies.modalPWA;

        if (showModalPWA === undefined) {
            if (rdd.isAndroid) {
                setModalAndroid(true);
            }

            if (rdd.isIOS) {
                setModalIphone(true);
            }
        }
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Bicentenario de la Independecia de Costa Rica"'}</title>
                <meta
                    name="description"
                    content={'Bicentenario de la Independecia de Costa Rica"'}
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                onClick={() => {
                    setModalAndroid(false);
                    setModalIphone(false);

                    setCookie('modalPWA', true, { path: '/', maxAge: 31536000 });
                }}>
                <Hero />
                <ModalIphoneCookie show={modalIphone} />
                <ModalAndroidCookie show={modalAndroid} />
            </div>
        </>
    );
}

export default Home;
