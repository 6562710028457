import axios from 'axios';
import PropTypes from 'prop-types';

export const PostRequest = async (url, variables) => {
    const headers = {
        Authorization: `Token ${process.env.REACT_APP_TOKEN_API}`,
        'Content-type': 'multipart/form-data'
    };

    try {
        return await axios.post(url, variables, {
            headers: headers
        });
    } catch (e) {
        console.log(e);
    }
};

PostRequest.propTypes = {
    url: PropTypes.any.isRequired,
    variables: PropTypes.any
};

export default PostRequest;

export const GetRequest = async (url) => {
    const headers = {
        Authorization: `Token ${process.env.REACT_APP_TOKEN_API}`
    };

    try {
        return await axios.get(url, {
            headers: headers
        });
    } catch (e) {
        console.log(e);
    }
};

GetRequest.propTypes = {
    url: PropTypes.any.isRequired
};
