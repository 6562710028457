import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';

const LazyImage = ({ src, alt, classNameWrapper, ...rest }) => {
    return (
        <LazyLoad className={classNameWrapper}>
            <img {...rest} src={src} alt={alt} />
        </LazyLoad>
    );
};

LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    classNameWrapper: PropTypes.string
};

export default LazyImage;
