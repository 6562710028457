import './locationRequestModal.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';

import AASafari from '../../assets/images/aa-safari.svg';
import ChoromeLock from '../../assets/images/chorome-lock.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import SettingSafari from '../../assets/images/setting-safari.svg';

function LocationRequestModal({ modal, onClick }) {
    return (
        <Modal
            show={modal.show}
            onHide={onClick}
            contentClassName="location-modal"
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Body>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img className="close-icon" onClick={onClick} src={CloseIcon} alt="close icon" />
                <p className="title">
                    El sitio web de “200 años Costa Rica” desea usar su ubicación actual.{' '}
                </p>
                <p className="description">
                    Este sitio requiere de su ubicación actual para mejarar su experiencia de uso y
                    así mostrarle las historias más cercanas a su posición{' '}
                </p>
                <p className="steps">Pasos para activarlo:</p>
                {steps()}
            </Modal.Body>
        </Modal>
    );

    function stepsSafari() {
        return (
            <>
                {' '}
                <p className="step">
                    1 .En Safari, haga click en <img src={AASafari} alt="" />{' '}
                </p>
                <p className="step">
                    2. Toca en
                    <b>
                        Configuraciones <img src={SettingSafari} alt="" />
                    </b>
                </p>
                <p className="step">
                    3. Click en <b>Ubicación</b>, permitir
                </p>
            </>
        );
    }

    function stepsOther() {
        return (
            <>
                {' '}
                <p className="step">
                    1 .En chorme, haga click en <img src={ChoromeLock} alt="" />{' '}
                </p>
                <p className="step">
                    2. Toca en
                    <b>Permisos</b>
                </p>
                <p className="step">
                    3. Click en <b>Ubicación</b>, permitir
                </p>
            </>
        );
    }

    function steps() {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            return stepsSafari();
        }

        return stepsOther();
    }
}

LocationRequestModal.propTypes = {
    modal: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};

export default LocationRequestModal;
