import './published.scss';

import React from 'react';
import { Button } from 'react-bootstrap';

import ImagePostDone from '../../assets/images/img-published.png';
const published = () => {
    const goToExplore = () => {
        window.location.href = '/explore/';
    };

    return (
        <div className="published-page">
            <img className="top-image" src={ImagePostDone} alt="" />
            <div className="content">
                <h1 className="title">
                    Felicidades, has contado parte de la historia de Costa Rica.
                </h1>

                <div className="buttons">
                    <Button onClick={goToExplore} variant="outline">
                        Continuar explorando
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default published;
