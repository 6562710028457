import { GoogleMap, LoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React from 'react';

import CustomMarker from './childs/customMarker.component';

const Map = ({ userMark, assets }) => {
    const containerStyle = {
        width: '100%',
        height: '361px'
    };

    const mapStyles = [];

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_GOOGLE_MAPS_API}>
            <GoogleMap
                mapContainerClassName="map-assets"
                options={{
                    styles: mapStyles
                }}
                mapContainerStyle={containerStyle}
                center={userMark.geolocation}
                zoom={16}>
                {assets &&
                    assets.map((item, index) => (
                        <CustomMarker
                            key={`event-map-${item.startDate}-${index}`}
                            position={{
                                lng: item.longitude,
                                lat: item.latitude
                            }}
                            icon={'/marker.png'}
                        />
                    ))}

                {userMark && assets === undefined && (
                    <CustomMarker position={userMark.geolocation} icon={'/marker.png'} />
                )}
            </GoogleMap>
        </LoadScript>
    );
};

Map.propTypes = {
    assets: PropTypes.array,
    userMark: PropTypes.any
};
export default Map;
