import './carouselCard.styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LazyImage from '../lazyImage/lazyImage.component';
// eslint-disable-next-line no-unused-vars
const CarouselCard = ({ id, icon, title, description, onClick, isAnonymous }) => {
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
            onClick={onClick}
            className="card card-small card-post card-post--1 mx-2 mx-sm-0 mb-4 mb-lg-0 carousel-card">
            <div className="card-body">
                <div className="card-title icon">
                    <LazyImage src={icon} alt="Card-Icon" />
                </div>

                <h3 className="card-title text-black text-capitalize carousel-card-title">
                    {truncate(title)}
                </h3>

                {isAnonymous === false && (
                    <p className="carousel-card-description">{description}</p>
                )}
            </div>
        </div>
    );
};

export const truncate = function (str) {
    return str.length > 30 ? str.substring(0, 30) + '...' : str;
};
CarouselCard.propTypes = {
    id: PropTypes.any.isRequired,
    icon: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isAnonymous: PropTypes.bool
};
export default CarouselCard;
